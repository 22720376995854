@font-face {
  font-family: 'Roboto';
  font-weight: normal;
  font-style: normal;
  font-display: swap;
  src: url('../fonts/Roboto-Regular.woff2'); /* IE9 Compat Modes */
  src: local(''), url('../fonts/Roboto-Regular.woff2') format('woff2'),
    /* Modern Browsers */ url('../fonts/Roboto-Regular.ttf') format('truetype'); /* Safari, Android, iOS */
}

@font-face {
  font-family: 'Montserrat';
  font-weight: normal;
  font-style: normal;
  font-display: swap;
  src: url('../fonts/Montserrat-Regular.woff2'); /* IE9 Compat Modes */
  src: local(''), url('../fonts/Montserrat-Regular.woff2') format('woff2'),
    /* Modern Browsers */ url('../fonts/Montserrat-Regular.ttf')
      format('truetype'); /* Safari, Android, iOS */
}

a {
  text-decoration: none;
  background-color: transparent;
  color: #52b1e0;
}

.MuiAutocomplete-option {
  background-color: white !important;
}
.MuiAutocomplete-option[aria-selected='true'].Mui-focused {
  background-color: rgba(25, 118, 210, 0.12) !important;
}
body {
  display: block;
  margin: 0px;
}

body * {
  box-sizing: border-box;
}

::-webkit-scrollbar {
  width: 10px;
  height: 10px;
  background-color: rgb(255, 255, 255);
}

::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: rgb(221, 218, 218);
}

.loading-table {
  display: flex;
  align-items: center;
  height: 56vh;
  justify-content: center;
}
.loading-center {
  grid-column: 1 / 13;
  display: flex;
  align-items: center;
  height: 56vh;
  justify-content: center;
}

h5.bold {
  font-family: 'Montserrat', sans-serif !important;
  font-weight: 600;
}

p.subtitle {
  font-family: 'Roboto', sans-serif !important;
  font-weight: 400 !important;
  margin: 1rem 0;
}

p.description {
  font-family: 'Roboto', sans-serif !important;
  font-weight: 600 !important;
}
